import React, { useEffect, useRef, useState } from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import {
  CarouselContainer,
  CarouselArrowWrapper,
  CarouselScrollContainer,
} from "./customCarousal.styles";

const CustomCarousal = ({ children, disableKeys }) => {
  const refScroller = useRef();
  const [renderAgain, setRenderAgain] = useState(false);
  console.log(renderAgain);
  const handleResize = () => {
    setTimeout(() => {
      setRenderAgain(new Date().getTime());
    }, 200);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize, false);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize, false);
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (disableKeys) {
        // Disable left and right arrow keys
        if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
          event.preventDefault();
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [disableKeys]);

  const swipe = (type) => {
    sideScroll(refScroller.current, type, 10, 300, 10);
  };

  const sideScroll = (element, direction, speed, distance, step) => {
    let scrollAmount = 0;
    let slideTimer = setInterval(() => {
      if (direction === "prev") {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
        setRenderAgain(new Date().getTime());
      }
    }, speed);
  };

  const rightScrollCheck = () =>
    refScroller.current &&
    refScroller.current.clientWidth + refScroller.current.scrollLeft <
      refScroller.current.scrollWidth - 10;

  const leftScrollCheck = () =>
    refScroller.current &&
    refScroller.current.clientWidth + refScroller.current.scrollLeft !==
      refScroller.current.clientWidth;

  return (
    <CarouselContainer>
      {leftScrollCheck() && (
        <CarouselArrowWrapper left onClick={() => swipe("prev")}>
          <span>
            <FaAngleLeft />
          </span>
        </CarouselArrowWrapper>
      )}
      <CarouselScrollContainer ref={refScroller}>
        {React.Children?.map(children, (child, index) => (
          <div key={index} style={{ flex: "0 0 auto", margin: "0 5px" }}>
            {child}
          </div>
        ))}
      </CarouselScrollContainer>
      {rightScrollCheck() && (
        <CarouselArrowWrapper onClick={() => swipe("next")}>
          <span>
            <FaAngleRight />
          </span>
        </CarouselArrowWrapper>
      )}
    </CarouselContainer>
  );
};

export default CustomCarousal;
