import React, { useEffect, useState } from "react";
import * as S from "./events.styles";
import { churchEvents, kidsEvents } from "../../Data"; // Ensure correct import path
import CustomCarousal from "../../components/CustomCarousal/CustomCarousal";
import DividerLine from "../../components/DividerLine";
import Modal from "../../components/modal/Modal";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import { useParams } from "react-router-dom";
const Events = () => {
  const [openImage, setOpenImage] = useState(false);
  const [selectedEventRow, setSelectedEventRow] = useState([]);
  const [imgPosition, setImagePosition] = useState(0);

  const { category } = useParams();
  console.log(category, "category");

  const [events, setEvents] = useState([]);

  useEffect(() => {
    if (openImage) {
      document.body.style.overflow = "hidden";
      setTimeout(() => document.getElementById("fullScreen").click(), 1000);
    } else {
      document.body.style.overflow = "auto";
    }
  }, [openImage]);
  useEffect(() => {
    if (category === "church") {
      setEvents(churchEvents);
    } else if (category === "kids") {
      setEvents(kidsEvents);
    }
  }, [category]);

  const handleSelectCategory = (event, imageIndex) => {
    setSelectedEventRow(event);
    setImagePosition(imageIndex);
    setOpenImage(true);
  };

  return (
    <S.MainContainer className="my-5">
      {events?.map((event) => (
        <S.EventSection key={event.id}>
          <S.EventTitle>{event.title}</S.EventTitle>
          <DividerLine color="#000" width="100%" height="2px" margin="0" />
          <S.SliderContainer>
            <CustomCarousal disableKeys={openImage}>
              {event?.images?.map((image, index) => (
                <S.ImageContainer
                  key={index}
                  onClick={() => handleSelectCategory(event, index)}
                >
                  <img src={image} alt={event.title} />
                </S.ImageContainer>
              ))}
            </CustomCarousal>
          </S.SliderContainer>
        </S.EventSection>
      ))}

      <Modal
        show={openImage}
        blur={false}
        closeModal={openImage}
        innerHeight="auto"
        innerWidth="80%"
        loader
        width="100%"
        height="100%"
        fixHeight="100vh"
        overflow="hidden"
      >
        <div id="fullScreen">
          <Carousel
            showThumbs={selectedEventRow.images?.length >= 2}
            showStatus={false}
            infiniteLoop={false}
            // emulateTouch
            // autoPlay
            showIndicators={false}
            useKeyboardArrows={true}
            transitionTime={1000}
            // axis="vertical"
            selectedItem={imgPosition}
            width="100%"
            renderArrowNext={(clickHandler, hasNext) => {
              return (
                hasNext && (
                  <span
                    style={{
                      cursor: "pointer",
                      position: "absolute",
                      right: "0",
                      top: "50%",
                      background: "#fff",
                      width: "40px",
                      height: "40px",
                      zIndex: 555,
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={clickHandler}
                  >
                    <MdOutlineArrowForwardIos
                      style={{
                        width: "60px",
                      }}
                    />
                  </span>
                )
              );
            }}
            renderArrowPrev={(clickHandler, hasNext) => {
              return (
                hasNext && (
                  <span
                    style={{
                      cursor: "pointer",
                      position: "absolute",
                      left: "0",
                      top: "50%",
                      background: "#fff",
                      width: "40px",
                      height: "40px",
                      zIndex: 555,
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={clickHandler}
                  >
                    <MdOutlineArrowBackIos
                      style={{
                        width: "20px",
                      }}
                    />
                  </span>
                )
              );
            }}
          >
            {selectedEventRow?.images?.map((sliderImage, i) => (
              <S.FullScreenImageContainer key={i}>
                <img
                  src={sliderImage}
                  alt={sliderImage}
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                    objectFit: "contain",
                    maxHeight: "100%",
                  }}
                />
              </S.FullScreenImageContainer>
            ))}
          </Carousel>
          <S.SliderCloseBtn onClick={() => setOpenImage(false)}>
            X
          </S.SliderCloseBtn>
        </div>
      </Modal>
    </S.MainContainer>
  );
};

export default Events;
