import styled from "styled-components";

export const MainContainer = styled.div`
  padding: 20px;
  width: 100%;
  margin: 0 auto;
  background: #f2f2f2;
  background: linear-gradient(
      to top,
      rgba(225, 225, 225, 0.3) 0%,
      rgba(225, 225, 225, 0.2) 100%
    ),
    url("https://images.unsplash.com/photo-1482952980430-be6f9ff18749?q=80&w=1074&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
`;
export const HeadSection = styled.div`
  text-align: center;
  h1 {
    font-family: "Dancing Script";
    font-size: 50px;
    filter: drop-shadow(3px 2px 2px white);
  }
`;
export const BodySection = styled.div``;

export const CardsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
`;

export const Card = styled.div`
  padding: 10px;
  /* border: 1px solid #ccc; */
  border-radius: 8px;
  overflow: hidden;
  max-width: 300px;
  min-width: 300px;
  width: 100%;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  transition: all 0.3s ease-in-out;
  margin-bottom: 20px;
  height: max-content;
  cursor: pointer;
  background: #fff;
  min-height: 440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const CardImage = styled.div`
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 250px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* border-bottom: 1px solid #ccc; */
    transition: transform 0.3s ease-in-out;
    border-radius: 5px;
  }
`;

export const CardBody = styled.div`
  /* padding: 15px; */
  text-align: center;
`;

export const CardTitle = styled.h5`
  margin-bottom: 10px;
  color: #333;
`;

export const CardText = styled.p`
  color: #666;
`;

export const CardFooter = styled.div`
  padding: 0 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
`;
export const CardButton = styled.button`
  padding: 5px 10px;
  width: max-content;
  border: none;
  border-radius: 4px;
  background: #ff6868;
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  gap: 5px;
`;
