import React from "react";
import * as S from "./offlineServices.styles";

const OfflineServices = () => {
  return (
    <S.MainContainer className="my-5">
      <h1>Coming Soon...</h1>
    </S.MainContainer>
  );
};

export default OfflineServices;
