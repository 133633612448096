import styled from "styled-components";

export const MainContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  display: grid;
  place-items: center;
`;
export const HeadSection = styled.div`
  text-align: center;
  h1 {
    font-family: "Dancing Script";
    font-size: 50px;
  }
`;
export const BodySection = styled.div`
  width: 100%;
  .center {
    display: flex;
    justify-content: center;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;

  @media screen and (max-width: 950px) {
    justify-content: center;
  }
`;

export const Card = styled.div`
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  max-width: 350px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transition: all 0.3s ease-in-out;
  margin-bottom: 20px;
  height: max-content;
  padding-bottom: 20px;
  max-height: 470px;
  min-height: 470px;
  cursor: pointer;

  &:hover img {
    transform: scale(1.1);
  }

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 6px 12px;
  }
`;

export const CardImage = styled.div`
  overflow: hidden;
  img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-bottom: 1px solid #ccc;
    transition: transform 0.3s ease-in-out;
  }
`;

export const CardBody = styled.div`
  padding: 10px 10px 0;
  min-height: 170px;
`;

export const CardTitle = styled.h4`
  color: #333;
  margin: 0;
`;

export const CardText = styled.p`
  color: #666;
  margin: 0;
`;

export const CardButton = styled.button`
  padding: 5px 10px;
  width: max-content;
  border: none;
  border-radius: 4px;
  background: #2281ee;
  color: #fff;
`;
export const CardFooter = styled.div`
  text-align: center;
  padding: 0 10px;
`;
