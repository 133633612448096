import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import pastorImage from "../../images/RaviAnna.png";
import Ayyagaru from "../../images/Ayyagaru.png";
import styles from "./about.module.css";

const About = () => {
  return (
    <Container fluid className={`mt-4 ${styles.aboutContainer}`} id="aboutPage">
      <Row className="align-items-center">
        <Col xs={12} md={3} className="text-center">
          <Image src={Ayyagaru} alt="Ayyagaru" fluid className={styles.image} />
          <div className="mt-2">
            <h5>Pas.K.Nireekshana Paul</h5>
            <p>Founder of Peniel Gospel Fellowship</p>
          </div>
        </Col>
        <Col xs={12} md={6}>
          <h2 className="text-center">
            Welcoming You to Peniel Gospel Fellowship
          </h2>

          <div>
            <p>
              At PGF Church, we're thrilled to welcome you into our loving
              community. Led by Pastor <strong>Ravi Kumar</strong> and{" "}
              <strong>Hepsiba</strong>, we're dedicated to creating a space
              where you can encounter the presence of God and discover your true
              purpose.
            </p>
            <p>
              PGF Church is an independent faith ministry founded on 7-6-1978 by
              <strong> Dr. K. Nireekshana Paul</strong> .
            </p>
            <p>
              Just as the Bible promises, we believe that when you become part
              of the family of God, you'll experience a flourishing life.
              Whether you're joining us in person or connecting online, get
              ready to elevate your spiritual journey and step into a new level
              of destiny.
            </p>
          </div>
        </Col>
        {/* Right side: Image */}
        <Col xs={12} md={3} className="text-center">
          <Image
            src={pastorImage}
            alt="Church"
            fluid
            className={styles.image}
          />
          <div className="mt-2">
            <h5>Pas.D.Ravi Kumar</h5>
            <p>PGF Telugu Church Banglore</p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default About;
