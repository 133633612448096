import styled from "styled-components";

export const CarouselContainer = styled.div`
  display: flex;
  position: relative;
`;

export const CarouselArrowWrapper = styled.div`
  height: 100%;
  position: absolute;
  background: linear-gradient(
    270deg,
    var(--sliderArrowBgOne) 0.57%,
    var(--sliderArrowBgTwo) 51.46%,
    var(--sliderArrowBgThree) 60.27%
  );
  z-index: 999;

  ${({ left }) => (left ? "left: 10px;" : "right: 10px;")}

  span {
    cursor: pointer;
    height: 30px;
    width: 30px;
    position: relative;
    top: 40%;
    background: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;

    ${({ left }) => (left ? "left: 6px;" : "right: -5px;")}
  }
`;

export const CarouselScrollContainer = styled.div`
  width: 100%;
  display: flex;
  overflow: auto;
  align-items: flex-start;
  justify-content: start;
  padding: 10px 0 12px;
  gap: 20px;
`;
