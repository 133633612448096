import styled from "styled-components";

export const MainContainer = styled.div`
  padding: 20px;
  width: 100%;
  margin: 0 auto;
  /* background: #f2f2f2; */
  /* background: linear-gradient(
      to top,
      rgba(225, 225, 225, 0.3) 0%,
      rgba(225, 225, 225, 0.2) 100%
    ),
    url("https://i.pinimg.com/600x315/ca/49/68/ca4968cad8f9fce7156f4b0d5b4f02d8.jpg"); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  height: 100%;
`;
export const HeadSection = styled.div`
  text-align: center;
`;
export const BodySection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  gap: 20px;

  @media (max-width: 980px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
// LeftImage
export const LeftImage = styled.img`
  width: 180px;
  height: 180px;
  position: relative;
  z-index: 10;
  transition: all 0.7s ease-in-out;
  transform: translateX(80px);
  box-shadow: 0px 0px 116px -21px rgba(0, 0, 0, 0.69);
  border-radius: ${(props) => (props.rounded ? "50%" : "none")};
  object-fit: cover;
  border: 5px solid #fff;

  @media (max-width: 980px) {
    width: 220px;
    height: 220px;
  }
  @media (max-width: 500px) {
    width: 160px;
    height: 160px;
  }
`;
export const ChurchEvents = styled.div`
  background: #eee;
  width: 45%;
  border-radius: 8px;
  height: auto;
  min-height: 550px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;

  @media (max-width: 980px) {
    width: 100%;
  }
`;

export const RightImage = styled.img`
  width: 180px;
  height: 180px;
  position: relative;
  z-index: 20;
  transition: all 0.7s ease-in-out;
  transform: translateX(-80px);
  box-shadow: 0px 0px 116px -21px rgba(0, 0, 0, 0.69);
  border-radius: ${(props) => (props.rounded ? "50%" : "none")};
  object-fit: cover;
  border: 5px solid #fff;
  @media (max-width: 980px) {
    width: 220px;
    height: 220px;
  }
  @media (max-width: 500px) {
    width: 160px;
    height: 160px;
  }
`;

export const ImagesSection = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  &:hover ${LeftImage} {
    transform: translateX(30px);
    rotate: -5deg;
  }
  &:hover ${RightImage} {
    transform: translateX(-30px);
    rotate: 5deg;
  }
  @media (max-width: 500px) {
    &:hover ${LeftImage} {
      transform: translateX(15px);
      rotate: -5deg;
    }
    &:hover ${RightImage} {
      transform: translateX(-15px);
      rotate: 5deg;
    }
  }
`;

export const CenterImage = styled.img`
  object-fit: cover;
  border: 5px solid #fff;
  width: 230px;
  height: 230px;
  position: relative;
  z-index: 22;
  box-shadow: 0px 0px 116px -21px rgba(0, 0, 0, 0.69);
  border-radius: ${(props) => (props.rounded ? "50%" : "none")};

  @media (max-width: 980px) {
    width: 280px;
    height: 280px;
  }
  @media (max-width: 500px) {
    width: 190px;
    height: 190px;
  }
`;

export const KidsEvents = styled.div`
  width: 45%;
  background: #eee;
  border-radius: 8px;
  height: auto;
  min-height: 550px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;

  @media (max-width: 980px) {
    width: 100%;
  }
`;
export const Button = styled.button`
  padding: 5px 10px;
  width: max-content;
  border: none;
  border-radius: 4px;
  background: #2281ee;
  color: #fff;
  transition: all 0.5s ease-in-out;
  background-image: linear-gradient(
    90deg,
    rgba(26, 41, 128, 0.2) 0,
    rgba(0, 255, 251, 0.2) 51%,
    rgba(26, 41, 128, 0.2)
  );
  cursor: pointer;
  display: flex;
  width: 200px;
  justify-content: center;
  align-items: center;

  :hover {
    background-position: 100%;
    color: #fff;
  }
`;
