import React from 'react';

const DividerLine = ({ height, width, margin, color, opacity }) => {
  const dividerStyles = {
    width: width || '100%',
    height: height || '1px',
    background: color || '#000',
    margin: margin || '20px 0',
    opacity: opacity || '0.1',
  };

  return <div style={dividerStyles}></div>;
};

export default DividerLine;
