// src/App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import TopBar from "./components/TopBar";
import OfflineServices from "./pages/offlineServices/guestSpeakers/OfflineServices";
import OnlineServices from "./pages/onlineServices/guestSpeakers/OnlineServices";
import Events from "./pages/ourEvents/Events";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <Router>
      <TopBar />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/offline-services" element={<OfflineServices />} />
        <Route path="/online-services" element={<OnlineServices />} />
        <Route path="/events/:category" element={<Events />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;
