import styled from "styled-components";
import SermonImg from "../../images/sermonsBgImage.png";
export const MainContainer = styled.div`
  padding: 20px;
  width: 100%;
  margin: 0 auto;
  background: #f2f2f2;
  height: 450px;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 80, 0.7)),
    url(${SermonImg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
`;
export const HeadSection = styled.div`
  text-align: center;
  h1 {
    font-family: "Dancing Script";
    font-size: 50px;
    color: white;
    filter: drop-shadow(3px 2px 2px black);
  }
`;
export const BodySection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CardsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
`;

export const Card = styled.div`
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  transition: all 0.3s ease-in-out;
  margin-bottom: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  box-shadow: -40px -5px 56px -34px rgba(0, 0, 0, 0.8);
`;

export const SliderSection = styled.div`
  margin: 20px 0;
  width: 100%;
`;

export const Button = styled.button`
  padding: 8px 25px;
  width: max-content;
  border: none;
  border-radius: 4px;

  cursor: pointer;
  display: flex;
  margin: 0 auto;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
  background: transparent;
  border: 1px solid white;
  color: #fff;
  &:hover {
    background: #fff;
    color: #000;
    border: 1px solid transparent;
  }
`;
