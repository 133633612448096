import styled from 'styled-components';

export const ModalBackdrop = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9999;
  display: ${props => (props.show ? 'block' : 'none')};
  backdrop-filter: ${props => (props.blur ? 'blur(0px)' : 'blur(8px)')};
  overflow: auto;
  background: ${props => props.backgroundColor};
`;

export const ModalContainer = styled.div`
  width: ${props => (props.width ? props.width : '700px')};
  background-color: ${props => (props.background ? props.background : '')};
  min-height: ${props => (props.height ? props.height : '290px')};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: ${props => props.fixHeight};
  overflow: ${props => (props.overflow ? props.overflow : 'auto')};
  box-shadow: ${props =>
    props.lightMode
      ? 'rgba(29,33,46,0.2) 5px 5px 8px 0px'
      : 'rgba(29, 33, 46, 0.5) 8px 8px 8px 0px'};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '0')};
  padding: ${props => (props.padding ? props.padding : 0)};
  box-sizing: border-box;
  box-shadow: -5px -5px 16px var(--grayGradient), 5px 5px 16px var(--darkGray);
`;

export const ModalInnerContainer = styled.div`
  margin: 0px 0px 20px 0px;
  height: ${props => props.innerHeight};
  width: ${props => props.innerWidth};
  position: relative;
`;

export const ModalHeader = styled.div`
  font-family: RubikMedium;
  font-size: 20px;
  color: #142c1f;
  text-align: center;
  margin-bottom: 30px;
`;

export const ModalBody = styled.div``;
