import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { mainImages } from "../Data";
import styles from "./home.module.css";
import About from "../components/about/About";

import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import Footer from "../components/footer/Footer";
import OurServices from "../components/ourServices/OurServices";
import GuestSpeakers from "../components/guestSpeakers/GuestSpeakers";
import LatestSermons from "../components/sermons/LatestSermons";
import OurEvents from "../components/ourEvents/OurEvents";
const Home = () => {
  return (
    <div className={styles.homeContaier}>
      <Carousel
        showThumbs={false}
        showStatus={false}
        infiniteLoop={true}
        animationHandler="fade"
        autoPlay
        showIndicators={true}
        interval={7000}
        useKeyboardArrows={true}
        swipeable={false}
        transitionTime={1000}
        showArrows={false}
        width="100%"
        stopOnHover={false}
        renderArrowNext={(clickHandler, hasNext) => {
          return (
            hasNext && (
              <FaChevronRight
                style={{
                  fontSize: "30px",
                  cursor: "pointer",
                  position: "absolute",
                  right: "0",
                  top: "50%",
                  opacity: "0.5",
                }}
                onClick={clickHandler}
              />
            )
          );
        }}
        renderArrowPrev={(clickHandler, hasNext) => {
          return (
            hasNext && (
              <FaChevronLeft
                style={{
                  fontSize: "30px",
                  cursor: "pointer",
                  position: "absolute",
                  left: "0",
                  top: "50%",
                  zIndex: 555,
                  opacity: "0.5",
                }}
                onClick={clickHandler}
              />
            )
          );
        }}
      >
        {mainImages?.map((sliderImage, i) => (
          <div className={styles.imageContainer} key={i}>
            {i === 0 && (
              <div className={styles.bannerInfo}>
                <h1>Welcome to PGF Family</h1>
                <h2>Where Love Abides and Faith Thrives</h2>
                <p className={styles.verse}>
                  "Beloved, let us love one another, for love is from God, and
                  whoever loves has been born of God and knows God." - 1 John
                  4:7
                </p>
              </div>
            )}
            <img src={sliderImage} className={styles.sliderImage} alt={i} />
          </div>
        ))}
      </Carousel>
      <About />
      <LatestSermons />
      <OurServices />
      <OurEvents />
      <GuestSpeakers />
      <Footer />
    </div>
  );
};

export default Home;
