import styled from "styled-components";

export const MainContainer = styled.div`
  padding: 20px;
  width: 100%;
  margin: 0 auto;
  background: #f2f2f2;
`;
export const HeadSection = styled.div`
  text-align: center;
`;
export const BodySection = styled.div``;

export const CardsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
`;

export const Card = styled.div`
  padding: 10px;
  /* border: 1px solid #ccc; */
  border-radius: 8px;
  overflow: hidden;
  max-width: 300px;
  width: 100%;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  transition: all 0.3s ease-in-out;
  margin-bottom: 20px;
  height: max-content;
  cursor: pointer;
`;

export const CardImage = styled.div`
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    /* border-bottom: 1px solid #ccc; */
    transition: transform 0.3s ease-in-out;
    border-radius: 50%;
  }
`;

export const CardBody = styled.div`
  padding: 15px;
  text-align: center;
`;

export const CardTitle = styled.h5`
  margin-bottom: 10px;
  color: #333;
`;

export const CardText = styled.p`
  color: #666;
`;

export const CardFooter = styled.div`
  padding: 0 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const CardButton = styled.button`
  padding: 5px 10px;
  width: max-content;
  border: none;
  border-radius: 4px;
  background: #ff6868;
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  gap: 5px;
`;
