import React from "react";
import * as S from "./ourServices.styles";
import { ourServicesData } from "../../Data";
import { useNavigate } from "react-router-dom";
const OurServices = () => {
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <>
      <S.MainContainer>
        <S.HeadSection>
          <h1>What we do</h1>
        </S.HeadSection>

        <S.BodySection>
          <S.CardsContainer>
            {ourServicesData.map((data) => (
              <S.Card key={data.id}>
                <S.CardImage>
                  <img src={data.image} alt={data.title} />
                </S.CardImage>
                <S.CardBody>
                  <S.CardTitle>{data.title}</S.CardTitle>
                  <S.CardText>{data.description}</S.CardText>
                </S.CardBody>
                <S.CardFooter>
                  <S.CardButton onClick={() => handleNavigate(data.path)}>
                    Know More
                  </S.CardButton>
                </S.CardFooter>
              </S.Card>
            ))}
          </S.CardsContainer>
        </S.BodySection>
      </S.MainContainer>
    </>
  );
};

export default OurServices;
