import React from "react";
import * as S from "./latestSermons.styles";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { latestSermonsData } from "../../Data";

const LatestSermons = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    easing: "easeOut",
    arrows: false,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const handleNavigate = () => {
    window.open(
      "https://www.youtube.com/@pgfteluguchurchbangalore2020/",
      "_blank"
    );
  };
  return (
    <S.MainContainer>
      <S.HeadSection>
        <h1 className="py-2">Latest Sermons</h1>
      </S.HeadSection>
      <S.BodySection>
        <S.SliderSection>
          <Slider {...settings}>
            {latestSermonsData.map((data) => (
              <div key={data.id}>
                <S.Card>
                  <iframe
                    width="400"
                    height="220"
                    src={data.youtubeLiveLink}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                  ></iframe>
                </S.Card>
              </div>
            ))}
          </Slider>
        </S.SliderSection>
      </S.BodySection>
      <S.Button onClick={handleNavigate}>Watch More</S.Button>
    </S.MainContainer>
  );
};

export default LatestSermons;
