import React from "react";
import * as S from "./guestSpeakers.styles";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ourGuestSpeakers } from "../../Data";
import { FaYoutube } from "react-icons/fa6";

const GuestSpeakers = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 1,
    // centerMode: true,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    // easing: 'easeOut',
    // fade:true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const handleNavigate = (url) => {
    window.open(url, "_blank");
  };
  return (
    <S.MainContainer className="my-5">
      <S.HeadSection>
        <h1 className="py-2">Our Guest Speakers</h1>
      </S.HeadSection>
      <S.BodySection>
        <Slider {...settings}>
          {ourGuestSpeakers?.map((data) => (
            <div key={data.id}>
              <S.Card>
                <S.CardImage>
                  <img src={data.image} alt={data.title} />
                </S.CardImage>
                <S.CardBody>
                  <S.CardTitle>{data.name}</S.CardTitle>
                  <S.CardText>{data.description}</S.CardText>
                </S.CardBody>
                <S.CardFooter>
                  <S.CardButton
                    onClick={() => handleNavigate(data.youtubeLink)}
                  >
                    <FaYoutube size={20} />
                    Watch Sermon
                  </S.CardButton>
                </S.CardFooter>
              </S.Card>
            </div>
          ))}
        </Slider>
      </S.BodySection>
    </S.MainContainer>
  );
};

export default GuestSpeakers;
