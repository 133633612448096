import React from "react";
import * as S from "./ourEvents.styles";
import { useNavigate } from "react-router-dom";
import { ChurchEventImages, KidsEventImages } from "../../Data";
const OurEvents = () => {
  const navigate = useNavigate();
  const handleNavigate = (url) => {
    navigate(`events/${url}`);
  };
  return (
    <S.MainContainer className="my-5">
      <S.HeadSection>
        <h1 className="py-2">Our Events</h1>
      </S.HeadSection>
      <S.BodySection>
        <S.ChurchEvents>
          <S.ImagesSection>
            <S.LeftImage src={ChurchEventImages[1]} alt="event" />
            <S.CenterImage src={ChurchEventImages[0]} alt="event" />
            <S.RightImage src={ChurchEventImages[2]} alt="event" />
          </S.ImagesSection>

          <h1>Church Events</h1>
          <S.Button onClick={() => handleNavigate("church")}>See More</S.Button>
        </S.ChurchEvents>
        <S.KidsEvents>
          <S.ImagesSection>
            <S.LeftImage rounded src={KidsEventImages[0]} alt="event" />
            <S.CenterImage rounded src={KidsEventImages[1]} alt="event" />
            <S.RightImage rounded src={KidsEventImages[2]} alt="event" />
          </S.ImagesSection>
          <h1>Kids Events</h1>
          <S.Button onClick={() => handleNavigate("kids")}>See More</S.Button>
        </S.KidsEvents>
      </S.BodySection>
    </S.MainContainer>
  );
};

export default OurEvents;
