import OutsideAlerter from "../OutsideAlerter";
import React from "react";
import * as S from "./modal.styles";

const Modal = (props) => {
  return (
    <S.ModalBackdrop
      show={props.show}
      style={{ ...props.style }}
      blur={props.blur ? true : false}
      backgroundColor={props.backgroundColor}
    >
      <OutsideAlerter closeModal={props.setShow}>
        <S.ModalContainer
          width={props.width}
          height={props.height}
          background={props.background}
          fixHeight={props.fixHeight}
          borderRadius={props.borderRadius}
          padding={props.padding}
          overflow={props.overflow}
          style={{ border: "none", ...props.innerStyle }}
        >
          <S.ModalInnerContainer
            innerHeight={props.innerHeight}
            innerWidth={props.innerWidth}
          >
            {props.heading && <S.ModalHeader>{props.heading}</S.ModalHeader>}
            <S.ModalBody
              style={props.styleOther ? { ...props.styleOther } : {}}
            >
              {props.children}
            </S.ModalBody>
          </S.ModalInnerContainer>
        </S.ModalContainer>
      </OutsideAlerter>
    </S.ModalBackdrop>
  );
};

export default Modal;
