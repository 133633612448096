// src/components/Navigation.js
import React, { useEffect, useState } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from "./topBar.module.css";
import Logo from "../images/PGFLOGO.png";

function TopBar() {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setIsSticky(scrollTop > 300);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <Navbar
      sticky="top"
      expand="lg"
      className={isSticky ? styles.stickyNavbar : styles.navbar}
    >
      <Container>
        <Navbar.Brand className="py-0" as={Link} to="/">
          <img width={50} height={50} src={Logo} alt="PGF logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link className={styles.navItem} as={Link} to="/">
              Home
            </Nav.Link>
            <Nav.Link
              className={styles.navItem}
              onClick={() => scrollToSection("aboutPage")}
            >
              About
            </Nav.Link>
            <Nav.Link
              className={styles.navItem}
              onClick={() => scrollToSection("aboutPage")}
            >
              Sunday School
            </Nav.Link>
            <Nav.Link
              className={styles.navItem}
              onClick={() => scrollToSection("footer")}
            >
              Events
            </Nav.Link>
            <Nav.Link
              className={styles.navItem}
              onClick={() => scrollToSection("footer")}
            >
              Contact
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default TopBar;
